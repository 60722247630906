var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "搜索商品标题" },
                    model: {
                      value: _vm.searchInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "name", $$v)
                      },
                      expression: "searchInfo.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "品牌" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请选择品牌",
                        remote: "",
                        "reserve-keyword": "",
                        "remote-method": _vm.getBrands
                      },
                      model: {
                        value: _vm.searchInfo.brandId,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "brandId", $$v)
                        },
                        expression: "searchInfo.brandId"
                      }
                    },
                    _vm._l(_vm.brands, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类目" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请选择类目",
                        remote: "",
                        "reserve-keyword": "",
                        "remote-method": _vm.getCategories
                      },
                      model: {
                        value: _vm.searchInfo.categoryId,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "categoryId", $$v)
                        },
                        expression: "searchInfo.categoryId"
                      }
                    },
                    _vm._l(_vm.categories, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择商品状态" },
                      model: {
                        value: _vm.searchInfo.status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "status", $$v)
                        },
                        expression: "searchInfo.status"
                      }
                    },
                    _vm._l(_vm.statusOption, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openDialog }
                    },
                    [_vm._v("新增商品")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { label: "商品ID", prop: "ID" } }),
          _c("el-table-column", { attrs: { label: "商品标题", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "图片", prop: "logo" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: { src: scope.row.logo, fit: "contain" }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", { attrs: { label: "库存", prop: "stock" } }),
          _c("el-table-column", {
            attrs: { label: "销量", prop: "sellCount" }
          }),
          _c("el-table-column", {
            attrs: { label: "状态", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.status === 1
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("上架")
                        ])
                      : scope.row.status === -1
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("下架")
                        ])
                      : scope.row.status === 2
                      ? _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("售罄")
                        ])
                      : _c("el-tag", { attrs: { type: "info" } }, [
                          _vm._v("未知")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-button",
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.updateEquityGoods(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "top", width: "160" },
                        model: {
                          value: scope.row.visible,
                          callback: function($$v) {
                            _vm.$set(scope.row, "visible", $$v)
                          },
                          expression: "scope.row.visible"
                        }
                      },
                      [
                        _c("p", [_vm._v("确定要删除吗？")]),
                        _c(
                          "div",
                          {
                            staticStyle: { "text-align": "right", margin: "0" }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    scope.row.visible = false
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteEquityGoods(scope.row)
                                  }
                                }
                              },
                              [_vm._v("确定")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              icon: "el-icon-delete",
                              size: "mini"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            visible: _vm.dialogFormVisible,
            title: _vm.type == "update" ? "编辑商品" : "创建商品"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "goodsForm",
              attrs: {
                model: _vm.formData,
                "label-position": "right",
                "label-width": "80px",
                rules: _vm.goodsRule
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品标题:", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "必填，例：星巴克电子券",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图片:", prop: "logo" } },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.imageAction,
                        "show-file-list": false,
                        "before-upload": _vm.imageBeforeUpload,
                        "on-success": _vm.handleAvatarSuccess
                      }
                    },
                    [
                      _vm.imageUrl
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imageUrl }
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon"
                          })
                    ]
                  ),
                  _c("span", { staticClass: "help" }, [
                    _vm._v("尺寸800*800, 建议大小200kb")
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "品牌", prop: "brandId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请选择品牌",
                        remote: "",
                        "reserve-keyword": "",
                        "remote-method": _vm.getBrands
                      },
                      model: {
                        value: _vm.formData.brandId,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "brandId", $$v)
                        },
                        expression: "formData.brandId"
                      }
                    },
                    _vm._l(_vm.brands, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "销量" } },
                [
                  _c("el-input", {
                    attrs: {
                      clearable: "",
                      placeholder: "销量",
                      maxlength: "20",
                      "show-word-limit": ""
                    },
                    model: {
                      value: _vm.formData.sellCount,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "sellCount", _vm._n($$v))
                      },
                      expression: "formData.sellCount"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类目", prop: "categoryId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: "请选择类目",
                        remote: "",
                        "reserve-keyword": "",
                        "remote-method": _vm.getCategories
                      },
                      model: {
                        value: _vm.formData.categoryId,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "categoryId", $$v)
                        },
                        expression: "formData.categoryId"
                      }
                    },
                    _vm._l(_vm.categories, function(item) {
                      return _c("el-option", {
                        key: item.ID,
                        attrs: { label: item.name, value: item.ID }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "规格", prop: "equity_goods_specs" } },
                [
                  _vm._l(_vm.formData.equity_goods_specs, function(
                    spec,
                    index
                  ) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c("el-input", {
                          attrs: {
                            clearable: "",
                            placeholder: "必填，例：星巴克电子券",
                            maxlength: "20",
                            "show-word-limit": ""
                          },
                          model: {
                            value: spec.name,
                            callback: function($$v) {
                              _vm.$set(spec, "name", $$v)
                            },
                            expression: "spec.name"
                          }
                        }),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              placeholder: "请选择货号",
                              remote: "",
                              "reserve-keyword": "",
                              "remote-method": _vm.getArtNoList
                            },
                            model: {
                              value: spec.artNoId,
                              callback: function($$v) {
                                _vm.$set(spec, "artNoId", $$v)
                              },
                              expression: "spec.artNoId"
                            }
                          },
                          _vm._l(_vm.artNoes, function(item) {
                            return _c(
                              "el-option",
                              {
                                key: item.ID,
                                attrs: { label: item.name, value: item.ID }
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(item.ID + "-" + item.name))
                                ])
                              ]
                            )
                          }),
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: { type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.deleteSpec(index)
                              }
                            }
                          },
                          [_vm._v("X")]
                        )
                      ],
                      1
                    )
                  }),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.addSpec } },
                    [_vm._v("添加规格")]
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }